<template>
    <CompactList :items="items">
        <template #list="{ item }">
            <UserOutput :user="item"></UserOutput>
        </template>
        <template #tooltipItem="{ item }">
            <UserOutput :user="item"></UserOutput>
        </template>
    </CompactList>
</template>

<script>
  import UserOutput from "@/components/Common/UserOutput";
  import CompactList from "@/components/Common/CompactList";

  export default {
    name: "WebmastersTableViewManagers",
    components: {
      CompactList,
      UserOutput
    },
    props: {
      items:{
        type: Array,
        required: true
      }
    }
  };
</script>

<style scoped>

</style>