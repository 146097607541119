<template>
    <Permissions
        :permissions="['WEBMASTERS.LOGIN.ALL', 'WEBMASTERS.LOGIN.OWN']"
        :validators="webmasterOwnValidators()">
        <LTooltip
            :label="$t(`admin.users.webmasters.table.buttons.${ row.isApproved ? 'signIn' : 'approved' }`)"
            position="is-left"
            type="is-success">
            <b-button
                :disabled="customArgumentsButton.disabled"
                :icon-left="customArgumentsButton.icon"
                :icon-pack="customArgumentsButton.pack"
                rounded
                size="is-small"
                type="is-success is-light"
                @click="customArgumentsButton.method">
            </b-button>
        </LTooltip>
    </Permissions>
</template>

<script>
  import Permissions from "@/components/Common/Permissions";
  import { mapActions } from "vuex";
  import {
    ADD_APPROVED_WEBMASTER,
    ADMIN_LOGIN_AS_WEBMASTER,
    GET_WEBMASTERS_WITH_BALANCE
  } from "@core/store/action-constants";

  export default {
    name: "WebmastersTableViewApproveOrSingIn",
    components: { Permissions },
      
    props: {
      row: {
        type: Object,
        required: true
      }
    },

    computed: {
      customArgumentsButton () {
        return {
          disabled: this.row.isApproved && this.row.activity.isBlocked,
          icon: this.row.isApproved ? "sign-in-alt" : "check-circle",
          pack: this.row.isApproved ? "fas" : "far",
          method: this.row.isApproved ? this.singInAsWebmaster : this.onApproved
        };
      }
    },
      
    methods: {
      ...mapActions({
        ADMIN_LOGIN_AS_WEBMASTER
      }),

      ...mapActions("admin/users/webmasters", {
        ADD_APPROVED_WEBMASTER,
        GET_WEBMASTERS_WITH_BALANCE
      }),
        
      webmasterOwnValidators () {
        const func = () => this.row.isBindedToCurrentAdmin;

        return {
          "WEBMASTERS.LOGIN.OWN": func,
          "WEBMASTERS.APPROVE.OWN": func,
          "WEBMASTERS.BLOCK.OWN": func,
          "WEBMASTERS.UNBLOCK.OWN": func,
          "WEBMASTERS.VIEW_HISTORY.OWN": func
        };
      },

      singInAsWebmaster () {
        this.ADMIN_LOGIN_AS_WEBMASTER(this.row.id);
      },

      onApproved () {
        const { id, login } = this.row;
          
        if (id) {
          this.$buefy.dialog.confirm({
            title: this.$t("admin.users.webmasters.confirm.approved"),
            confirmText: this.$t("admin.users.webmasters.confirm.confirmButtonText"),
            cancelText: this.$t("admin.users.webmasters.confirm.cancelButtonText"),
            type: "is-info is-light",
            onConfirm: async () => {
              try {
                await this.ADD_APPROVED_WEBMASTER(id);
                this.toastSuccess(this.$t("admin.users.webmasters.messages.approve.success", { userLogin: login }));
                this.GET_WEBMASTERS_WITH_BALANCE();
              } catch (err) {
                this.toastFail(this.$t("admin.users.webmasters.messages.approve.fail", { userLogin: login }), err);
              }
            }
          });
        }
      }
    }
  };
</script>

<style scoped>

</style>