<template>
    <div>
        <ModalBox
            :is-active.sync="isActive"
            :model="modelProfile"
            width="50vw"
            @close="close"
            @submit="edit">
            <template #header>
                <h5 class="title is-5">
                    {{ $t(`admin.users.webmasters.modal.edit.header.title`) }}
                </h5>
            </template>
            <WebmasterProfile
                ref="ruleForm"
                :user.sync="localForm">
                <template #buttons>
                    <div class="container">
                        <div class="columns">
                            <div class="column">
                                <b-button
                                    class="is-fullwidth is-info is-light"
                                    expanded
                                    @click="openPasswordModal">
                                    {{ $t(`admin.profile.modal.footer.buttons.changePassword`) }}
                                </b-button>
                            </div>
                            <div class="column">
                                <b-button
                                    expanded
                                    native-type="submit"
                                    class="is-success is-light"
                                    :disabled="!form">
                                    {{ $t(`admin.users.webmasters.modal.edit.buttons.save`) }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </template>
            </WebmasterProfile>
            <b-loading
                v-model="isLoading"
                :is-full-page="false">
            </b-loading>
        </ModalBox>
        <PasswordModal
            :is-active.sync="isPasswordModalActive"
            :is-loading="isPasswordLoading"
            :password.sync="password"
            @submit="changePassword"
            @closed="emptyPasswordState">
        </PasswordModal>
    </div>
</template>

<script>
  import PasswordModal from "@/components/Admin/Users/common/PasswordModal";
  import ModalBox from "@/components/Common/ModalBox";
  import WebmasterProfile from "@/components/Common/Webmaster/WebmasterProfile";
  import { EDIT_PASSWORD, EDIT_WEBMASTER, GET_WEBMASTER, GET_WEBMASTERS_WITH_BALANCE } from "@core/store/action-constants";
  import {
    SET_EMPTY,
    SET_IS_WEBMASTERS_EDIT_MODAL_ACTIVE,
    UPDATE_MODAL,
    UPDATE_PASSWORD_FORM,
    UPDATE_WEBMASTERS_EDIT_FORM
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "WebmastersEditModal",
    
    components: {
      ModalBox,
      WebmasterProfile,
      PasswordModal
    },
    
    data () {
      return {
        localForm: null
      };
    },

    computed: {
      ...mapState("admin/users/webmasters/edit", [
        "webmasterId",
        "form"
      ]),

      ...mapFields("admin/users/webmasters/edit/password", {
        fields: [
          "isPasswordModalActive"
        ],
        base: "modal",
        action: UPDATE_MODAL
      }),

      ...mapFields("admin/users/webmasters/edit/password", {
        fields: [
          "password"
        ],
        base: "form",
        action: UPDATE_PASSWORD_FORM
      }),

      modelProfile () {
        return {
          telegram: this.form?.telegram,
          skype: this.form?.additional?.skype
        };
      },

      isActive: {
        get () {
          return this.$store.state.admin.users.webmasters.edit.isModalActive;
        },

        set (value) {
          this.SET_IS_WEBMASTERS_EDIT_MODAL_ACTIVE(value);
        }
      },

      isLoading () {
        return this.$wait(`admin/users/webmasters/edit/${ EDIT_WEBMASTER }`) || this.$wait(`admin/users/webmasters/edit/${ GET_WEBMASTER }`);
      },

      isPasswordLoading () {
        return this.$wait(`admin/users/webmasters/edit/password/${ EDIT_PASSWORD }`);
      }
    },
    
    methods: {
      ...mapActions("admin/users/webmasters", {
        GET_WEBMASTERS_WITH_BALANCE
      }),

      ...mapActions("admin/users/webmasters/edit", [
        GET_WEBMASTER,
        EDIT_WEBMASTER
      ]),

      ...mapActions("admin/users/webmasters/edit/password", [
        EDIT_PASSWORD
      ]),

      ...mapMutations("admin/users/webmasters/edit", [
        UPDATE_WEBMASTERS_EDIT_FORM,
        SET_IS_WEBMASTERS_EDIT_MODAL_ACTIVE,
        SET_EMPTY
      ]),

      ...mapMutations("admin/users/webmasters/edit/password", {
        emptyPasswordState: SET_EMPTY
      }),

      openPasswordModal () {
        this.isPasswordModalActive = true;
      },

      async changePassword () {
        try {
          await this.EDIT_PASSWORD();
          this.emptyPasswordState();

          this.$buefy.toast.open({
            message: this.$t("admin.users.common.modal.password.messages.success"),
            type: "is-success"
          });
        } catch (error) {
          this.$buefy.toast.open({
            message: this.$t("admin.users.common.modal.password.messages.fail"),
            type: "is-danger"
          });
        }
      },

      async edit () {
        try {
          await this.EDIT_WEBMASTER();

          this.close();
          this.GET_WEBMASTERS_WITH_BALANCE();
          this.$buefy.toast.open({
            message: this.$t("admin.users.common.modal.edit.messages.success"),
            type: "is-success"
          });

        } catch (_) {
          this.$buefy.toast.open({
            message: this.$t("admin.users.common.modal.edit.messages.fail"),
            type: "is-danger"
          });
        }
      },

      close () {
        if (!this.isPasswordModalActive) {
          this.SET_EMPTY();
          this.localForm = null;
        }
      }
    },

    watch: {
      async webmasterId (value) {
        if (value) {
          await this.GET_WEBMASTER();
          this.localForm = _cloneDeep(this.form);
        }
      },

      localForm: {
        deep: true,
        handler (value) {
          this.UPDATE_WEBMASTERS_EDIT_FORM(value);
        }
      },

      isPasswordModalActive (value) {
        this.isActive = !value;
      }
    }
  };
</script>

<style></style>
