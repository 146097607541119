<template>
    <DropdownOptions
        v-if="row"
        rounded>
        <Permissions :permissions="['WEBMASTERS.EDIT.ALL', 'WEBMASTERS.EDIT.OWN']">
            <DropdownOption
                icon="square-edit"
                type="is-warning is-light"
                @click="onEditWebmaster">
                {{ $t("admin.users.webmasters.table.buttons.edit") }}
            </DropdownOption>
        </Permissions>

        <Permissions :permissions="['WEBMASTERS.TOGGLE_ACCESS_TO_API.ALL', 'WEBMASTERS.TOGGLE_ACCESS_TO_API.OWN']">
            <DropdownOption
                icon="code"
                :type="apiButtonType(row.isEnableApi)"
                @click="onToggleApi">
                {{ $t(`admin.users.webmasters.table.buttons.${row.isEnableApi ? "disablePublicApi" : "enablePublicApi"}`) }}
            </DropdownOption>
        </Permissions>

        <Permissions :permissions="['WEBMASTERS.TOGGLE_PARTNER_NETWORK.ALL', 'WEBMASTERS.TOGGLE_PARTNER_NETWORK.OWN']">
            <DropdownOption
                icon="hubspot"
                type="is-warning is-light"
                @click="onTogglePartnerNetwork">
                {{ this.$t(`admin.users.webmasters.table.buttons.${row.isPartnerNetwork ? "disablePartnerNetwork" : "enablePartnerNetwork"}`) }}
            </DropdownOption>
        </Permissions>

        <DropdownOption
            v-show="bindPermissions === 'all'"
            icon="account-plus"
            type="is-success is-light"
            @click="$emit('updateBind', { id: row.id, login: row.login, admins: row.admins })">
            {{ $t(`admin.users.common.table.buttons.${ row.admins.length !== 0 ? 'changeManager' : 'bindManager' }`) }}
        </DropdownOption>

        <DropdownOption
            v-show="bindPermissions === 'free' && !row.admin"
            icon="account-plus"
            type="is-success is-light"
            @click="bindToSelf">
            {{ $t("admin.users.common.table.buttons.bindToSelf") }}
        </DropdownOption>

        <Permissions :permissions="['SUPER.PERMISSION']">
            <DropdownOption
                icon="hand-holding-usd"
                type="is-warning is-light"
                @click="$emit('updateFinancesCostsCreatePaymentModal', row.id)">
                {{ $t("admin.users.webmasters.table.buttons.createPaymentWebmaster") }}
            </DropdownOption>
        </Permissions>

        <DropdownOption
            v-show="!row.activity.isConfirmed"
            icon="envelope"
            type="is-success is-light"
            @click="resendEmailByAdmin">
            {{ $t("common.mailConfirmation.resendEmail.resend") }}
        </DropdownOption>

        <Permissions
            :permissions="[
                'WEBMASTERS.BLOCK.ALL',
                'WEBMASTERS.BLOCK.OWN',
                'WEBMASTERS.UNBLOCK.ALL',
                'WEBMASTERS.UNBLOCK.OWN'
            ]"
            :validators="webmasterOwnValidators()">
            <DropdownOption
                :icon="blockButtonIcon(row.activity.isBlocked)"
                :type="blockButtonType(row.activity.isBlocked)"
                @click="onToggleBlock">
                {{ $t(`admin.users.webmasters.table.buttons.${ row.activity.isBlocked ? 'unblock' : 'block' }`) }}
            </DropdownOption>
        </Permissions>

        <Permissions
            :permissions="['WEBMASTERS.VIEW_HISTORY.ALL', 'WEBMASTERS.VIEW_HISTORY.OWN']"
            :validators="webmasterOwnValidators()">
            <DropdownOption
                icon="clock"
                @click="$emit('updateShowHistory', row.id)">
                {{ $t("admin.users.webmasters.table.buttons.actionsHistory") }}
            </DropdownOption>
        </Permissions>
    </DropdownOptions>
</template>

<script>
  import DropdownOptions from "@/components/Common/DropdownOptions/DropdownOptions";
  import Permissions from "@/components/Common/Permissions";
  import DropdownOption from "@/components/Common/DropdownOptions/DropdownOption";
  import TableOptionsMixin from "@/components/Admin/Users/TableOptionsMixin";
  import { hasPermissions } from "@core/mixins/permissions";
  import { mapActions, mapMutations, mapState } from "vuex";
  import {
    SET_IS_WEBMASTERS_EDIT_MODAL_ACTIVE,
    SET_WEBMASTERS_EDIT_ID
  } from "@core/store/mutation-constants";
  import {
    ADD_WEBMASTER_PERSONAL_MANAGER, BLOCK_WEBMASTER,
    DISABLE_WEBMASTER_PARTNER_NETWORK,
    DISABLE_WEBMASTER_PUBLIC_API,
    ENABLE_WEBMASTER_PARTNER_NETWORK,
    ENABLE_WEBMASTER_PUBLIC_API,
    GET_WEBMASTERS_WITH_BALANCE, RESEND_EMAIL_BY_ADMIN, UNBLOCK_WEBMASTER
  } from "@core/store/action-constants";

  export default {
    name: "WebmastersTableViewOptions",
    mixins: [TableOptionsMixin],
    components: {
      DropdownOption,
      Permissions,
      DropdownOptions
    },
      
    props: {
      row: {
        type: Object,
        required: true
      }
    },
      
    computed: {
      ...mapState("admin", {
        profile: state => state.profile,
        adminUserIdState: state => state.profile.user.id
      }),
        
      userPermissions () {
        return (this.$store || { getters: {} }).getters.permissions;
      },
        
      bindPermissions () {
        const all = hasPermissions(["WEBMASTERS.BIND.ALL"], this.userPermissions);
        const free = hasPermissions(["WEBMASTERS.BIND.FREE"], this.userPermissions);

        if (all) {
          return "all";
        } else if (free && !all) {
          return "free";
        } else {
          return null;
        }
      }
    },

    methods: {
      ...mapActions("admin/users/webmasters", {
        ENABLE_WEBMASTER_PARTNER_NETWORK,
        ENABLE_WEBMASTER_PUBLIC_API,
        DISABLE_WEBMASTER_PARTNER_NETWORK,
        DISABLE_WEBMASTER_PUBLIC_API,
        GET_WEBMASTERS_WITH_BALANCE,
        ADD_WEBMASTER_PERSONAL_MANAGER,
        BLOCK_WEBMASTER,
        UNBLOCK_WEBMASTER,
        RESEND_EMAIL_BY_ADMIN
      }),
        
      ...mapMutations("admin/users/webmasters/edit", [
        SET_IS_WEBMASTERS_EDIT_MODAL_ACTIVE,
        SET_WEBMASTERS_EDIT_ID
      ]),

      onEditWebmaster () {
        this.SET_IS_WEBMASTERS_EDIT_MODAL_ACTIVE(true);
        this.SET_WEBMASTERS_EDIT_ID(this.row.id);
      },

      onTogglePartnerNetwork () {
        const { id, isPartnerNetwork } = this.row;

        const title = isPartnerNetwork
          ? this.$t("admin.users.webmasters.confirm.disablePartnerNetwork")
          : this.$t("admin.users.webmasters.confirm.enablePartnerNetwork");

        this.$buefy.dialog.confirm({
          title,
          confirmText: this.$t("admin.users.webmasters.confirm.confirmButtonText"),
          cancelText: this.$t("admin.users.webmasters.confirm.cancelButtonText"),
          type: "is-info is-light",
          onConfirm: async () => {
            if (isPartnerNetwork) {
              await this.DISABLE_WEBMASTER_PARTNER_NETWORK(id);
            } else {
              await this.ENABLE_WEBMASTER_PARTNER_NETWORK(id);
            }

            this.GET_WEBMASTERS_WITH_BALANCE();
          }
        });
      },

      onToggleApi () {
        const { id, isEnableApi } = this.row;

        this.$buefy.dialog.confirm({
          title: this.$t(`admin.users.webmasters.confirm.${ isEnableApi ? "disablePublicApi" : "enablePublicApi" }`),
          confirmText: this.$t("admin.users.webmasters.confirm.confirmButtonText"),
          cancelText: this.$t("admin.users.webmasters.confirm.cancelButtonText"),
          type: "is-info is-light",
          onConfirm: async () => {
            if (isEnableApi) {
              await this.DISABLE_WEBMASTER_PUBLIC_API(id);
            } else {
              await this.ENABLE_WEBMASTER_PUBLIC_API(id);
            }

            this.GET_WEBMASTERS_WITH_BALANCE();
          }
        });
      },

      bindToSelf () {
        const { id, login } = this.row;
          
        this.$buefy.dialog.confirm({
          title: this.$t("admin.users.webmasters.confirm.bindToSelf.title"),
          message: this.$t("admin.users.webmasters.confirm.bindToSelf.message"),
          confirmText: this.$t("admin.users.webmasters.confirm.bindToSelf.confirm"),
          cancelText: this.$t("common.entity.modal.confirmationToCancel.cancelText"),
          type: "is-success is-light",
          onConfirm: async () => {
            try {
              await this.ADD_WEBMASTER_PERSONAL_MANAGER({ userId: id, adminId: this.adminUserIdState });
              this.toastSuccess(this.$t("admin.users.webmasters.messages.bind.success", { login }));
              this.GET_WEBMASTERS_WITH_BALANCE();
            } catch (err) {
              this.toastFail(this.$t("admin.users.webmasters.messages.bind.fail", { login }), err);
            }
          }
        });
      },

      async resendEmailByAdmin () {
        try {
          await this.RESEND_EMAIL_BY_ADMIN(this.row.id);

          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.success"),
            type: "is-success"
          });
        }
        catch (error) {
          this.$buefy.toast.open({
            message: this.$t("common.mailConfirmation.resendEmail.fail"),
            type: "is-danger"
          });
        }
      },

      onToggleBlock () {
        const { id, activity: { isBlocked }, login } = this.row;

        if (id) {
          const title = isBlocked
            ? this.$t("admin.users.webmasters.confirm.isUnBlocked")
            : this.$t("admin.users.webmasters.confirm.isBlocked");

          this.$buefy.dialog.confirm({
            title,
            confirmText: this.$t("admin.users.webmasters.confirm.confirmButtonText"),
            cancelText: this.$t("admin.users.webmasters.confirm.cancelButtonText"),
            type: "is-info is-light",
            onConfirm: async () => {
              if (isBlocked) {
                try {
                  await this.UNBLOCK_WEBMASTER(id);
                  this.toastSuccess(this.$t("admin.users.webmasters.messages.unblock.success", { userLogin: login }));
                  this.GET_WEBMASTERS_WITH_BALANCE();
                } catch (err) {
                  this.toastFail(this.$t("admin.users.webmasters.messages.unblock.fail", { userLogin: login }), err);
                }
              } else {
                try {
                  await this.BLOCK_WEBMASTER(id);
                  this.toastSuccess(this.$t("admin.users.webmasters.messages.block.success", { userLogin: login }));
                  this.GET_WEBMASTERS_WITH_BALANCE();
                } catch (err) {
                  this.toastFail(this.$t("admin.users.webmasters.messages.block.fail", { userLogin: login }), err);
                }
              }
            }
          });
        }
      },

      webmasterOwnValidators () {
        const func = () => this.row.isBindedToCurrentAdmin;

        return {
          "WEBMASTERS.LOGIN.OWN": func,
          "WEBMASTERS.APPROVE.OWN": func,
          "WEBMASTERS.BLOCK.OWN": func,
          "WEBMASTERS.UNBLOCK.OWN": func,
          "WEBMASTERS.VIEW_HISTORY.OWN": func
        };
      }
    }
  };
</script>

<style scoped>

</style>