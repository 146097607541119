<template>
    <div>
        <TablePlaceholder :data="webmasters">
            <template slot-scope="{ count }">
                <CustomizableTable
                    :data="computedTableData"
                    :current-page.sync="page"
                    :sort-change="sortOption"
                    :per-page.sync="perPage"
                    :total="count"
                    :is-loading="isLoading.getWebmasters"
                    :columns="computedColumns">
                </CustomizableTable>

                <WebmastersTableDetailsModal
                    :is-active.sync="detailsModal"
                    :webmaster="detailsModalData">
                </WebmastersTableDetailsModal>

                <FinancesCostsCreatePaymentModal
                    :user="webmasterUserId"
                    @update="GET_WEBMASTERS_WITH_BALANCE">
                </FinancesCostsCreatePaymentModal>

                <ModalBindUserToAdmin
                    v-if="isBindModalVisible"
                    :admin="admin"
                    :delete-method="DELETE_WEBMASTER_PERSONAL_MANAGER"
                    :is-active.sync="isBindModalVisible"
                    :is-loading="isBindLoading"
                    :method="ADD_WEBMASTER_PERSONAL_MANAGER"
                    :user-id="webmasterUserId"
                    :user-login="webmasterUserLogin"
                    role="webmasters"
                    @update="GET_WEBMASTERS_WITH_BALANCE">
                </ModalBindUserToAdmin>

                <ModalBox :is-active.sync="isHistoryDialogVisible">
                    <template #header>
                        <h5 class="title is-5">
                            {{ $t(`admin.users.webmasters.modal.history.header.historyTitle`) }}
                        </h5>
                    </template>

                    <TablePlaceholder :data="historyWebmasters">
                        <template slot-scope="{ items: _items, count: _count }">
                            <FTable
                                :data="_items"
                                :total="_count">
                                <el-table-column
                                    v-slot="{ row }"
                                    :label="$t(`admin.users.webmasters.modal.history.body.table.labels.dateTime`)">
                                    {{ formatEmptyString(moment(row.createdAt)) }}
                                </el-table-column>

                                <el-table-column
                                    :label="$t(`admin.users.webmasters.modal.history.body.table.labels.action`)">
                                    {{ formatEmptyString() }}
                                </el-table-column>

                                <el-table-column
                                    v-slot="{ row }"
                                    :label="$t(`admin.users.webmasters.modal.history.body.table.labels.note`)">
                                    {{ formatEmptyString(row.action) }}
                                </el-table-column>
                            </FTable>
                        </template>
                    </TablePlaceholder>
                </ModalBox>
            </template>
        </TablePlaceholder>
    </div>
</template>

<script>
  import ModalBindUserToAdmin from "@/components/Admin/Users/ModalBindUserToAdmin";
  import DropdownComment from "@/components/Common/DropdownComment";
  import EmailOutput from "@/components/Common/EmailOutput";
  import ModalBox from "@/components/Common/ModalBox";
  import ShowMore from "@/components/Common/ShowMore";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import TelegramOutput from "@/components/Common/TelegramOutput";
  import BalanceOutput from "@/components/Admin/Users/WebmastersTab/BalanceOutput";
  import PreferredCurrencyCheckbox from "@/components/Common/PreferredCurrencyCheckbox";
  import { TableVuex } from "@core/mixins";
  import {
    ADD_WEBMASTER_PERSONAL_MANAGER,
    DELETE_WEBMASTER_PERSONAL_MANAGER,
    EDIT_WEBMASTER_ADMIN_COMMENT,
    GET_HISTORY_WEBMASTER,
    GET_WEBMASTERS,
    GET_WEBMASTERS_BY_ID,
    GET_WEBMASTERS_WITH_BALANCE,
    UPDATE_MODAL_ACTIVE
  } from "@core/store/action-constants";
  import {
    EMPTY_WEBMASTER_EDITABLE_COMMENT,
    UPDATE_ADMINS_DICTIONARY,
    UPDATE_WEBMASTER_EDITABLE_COMMENT,
    UPDATE_WEBMASTERS_PAGINATION,
    UPDATE_WEBMASTERS_SORTINGS
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  import FinancesCostsCreatePaymentModal from "@/components/Admin/Finances/FinancesCosts/FinancesCostsCreatePaymentModal";
  import WebmastersTableDetailsModal from "@/components/Admin/Users/WebmastersTab/WebmastersTableDetailsModal";
  import FTable from "@/components/Common/FTable";
  import CustomizableTable from "@/components/Common/CustomizableTable";
  import { formatEmptyString, formatCurrency, toFixed, moment } from "@core/filters";
  import WebmastersTableViewGroup from "@/components/Admin/Users/WebmastersTab/TableViews/WebmastersTableViewGroup";
  import WebmastersTableViewOptions from "@/components/Admin/Users/WebmastersTab/TableViews/WebmastersTableViewOptions";
  import WebmastersTableViewManagers from "@/components/Admin/Users/WebmastersTab/TableViews/WebmastersTableViewManagers";
  import WebmastersTableViewApproveOrSingIn
      from "@/components/Admin/Users/WebmastersTab/TableViews/WebmastersTableViewApproveOrSingIn";

  export default {
    name: "WebmastersTable",
    mixins: [TableVuex],
    components: {
      ModalBindUserToAdmin,
      WebmastersTableDetailsModal,
      FinancesCostsCreatePaymentModal,
      TablePlaceholder,
      ModalBox,
      FTable,
      CustomizableTable
    },

    data () {
      return {
        isVisiblePreferredCurrency: true,
        isHistoryDialogVisible: false,
        isBindModalVisible: false,
        webmasterUserId: null,
        webmasterUserLogin: null,
        admin: null,
        detailsModal: false,
        detailsModalData: null,
        defaultSortDirection: "asc",
        sortIcon: "arrow-up",
        sortIconSize: "is-small"
      };
    },

    computed: {
      ...mapState("admin", {
        isImportOrdersByApiModalActive: ({ users }) => users.webmasters.isImportOrdersByApiModalActive,
        historyWebmasters: ({ users }) => users.webmasters.historyWebmasters,
        statistics: ({ statistic }) => statistic.statistics,
        webmasters: (state) => state.users.webmasters.webmasters
      }),

      ...mapFields("admin/users/webmasters", {
        fields: ["perPage", "page"],
        base: "pagination",
        action: UPDATE_WEBMASTERS_PAGINATION
      }),

      isLoading () {
        return {
          getWebmasters: this.$wait(`admin/users/webmasters/${ GET_WEBMASTERS }`),
          updateComment: this.$wait(`admin/users/webmasters/${ EDIT_WEBMASTER_ADMIN_COMMENT }`)
        };
      },

      isBindLoading () {
        return this.$wait(`admin/users/webmasters/${ ADD_WEBMASTER_PERSONAL_MANAGER }`) ||
          this.$wait(`admin/users/webmasters/${ DELETE_WEBMASTER_PERSONAL_MANAGER }`);
      },

      isPaginated () {
        return this.webmasters.count > this.perPage;
      },

      computedTableData () {
        return this.webmasters?.items;
      },

      computedColumns () {
        return [
          {
            field: "intId",
            key: "0",
            title: this.$t("admin.users.webmasters.table.labels.id"),
            sortBy: "asc",
            renderBodyCell: ({ row }) => formatEmptyString(row.intId)
          },
          {
            field: "login",
            key: "1",
            title: this.$t("admin.users.webmasters.table.labels.login"),
            align: "left",
            renderBodyCell: ({ row }) =>
              <WebmastersTableViewGroup
                  row={ row }
                  v-on:updateDetailsModalData={ this.webmastersTableDetailsModalOpen }>
              </WebmastersTableViewGroup>
          },
          {
            field: "balance",
            key: "2",
            title: this.$t("admin.users.webmasters.table.labels.balance"),
            sortBy: "asc",
            renderHeaderCell: () => {
                return (
                        <div class="flex">
                            <PreferredCurrencyCheckbox
                                is-visible-preferred-currency={ this.isVisiblePreferredCurrency }
                                name-local-storage="isVisiblePreferredCurrencyAdvertisersBalance"
                                v-on:isVisiblePreferredCurrency={ this.updateIsVisiblePreferredCurrency }
                                class="mr-2">
                            </PreferredCurrencyCheckbox>
                            { this.$t("admin.users.webmasters.table.labels.balance") }
                        </div>
                );
            },
            renderBodyCell: ({ row }) => <BalanceOutput
                                            balance={ row.balance }
                                            is-visible-preferred-currency={ this.isVisiblePreferredCurrency }>
                                          </BalanceOutput>
          },
          {
            field: "email",
            key: "3",
            title: this.$t("admin.users.webmasters.table.labels.email"),
            align: "left",
            renderBodyCell: ({ row }) => <EmailOutput email={ row.contact.email }></EmailOutput>
          },
          {
            field: "skype",
            key: "4",
            title: this.$t("admin.users.webmasters.table.labels.skype"),
            align: "left",
            renderBodyCell: ({ row }) => formatEmptyString(row.skype)
          },
          {
            field: "telegram",
            key: "5",
            title: this.$t("admin.users.webmasters.table.labels.telegram"),
            align: "left",
            renderBodyCell: ({ row }) => <TelegramOutput telegram={ row.contact.telegram }></TelegramOutput>
          },
          {
            field: "birthday",
            key: "6",
            title: this.$t("admin.users.webmasters.table.labels.birthday"),
            align: "left",
              sortBy: "asc",
            renderBodyCell: ({ row }) => formatEmptyString(moment(row.birthday, "DD MMMM YYYY"))
          },
          {
            field: "registeredAt",
            key: "7",
            title: this.$t("admin.users.webmasters.table.labels.regDate"),
            align: "left",
            sortBy: "asc",
            renderBodyCell: ({ row }) => formatEmptyString(moment(row.activity.registeredAt))
          },
          {
            field: "lastTransactionDate",
            key: "8",
            title: this.$t("admin.users.webmasters.table.labels.lastTransactionDate"),
            align: "left",
            renderBodyCell: ({ row }) => formatEmptyString(moment(row.lastTransactionDate))
          },
          {
            field: "admin",
            key: "9",
            title: this.$t("admin.users.webmasters.table.labels.admin"),
            align: "left",
            renderBodyCell: ({ row }) => <WebmastersTableViewManagers items={ row.admins }></WebmastersTableViewManagers>
          },
          {
            field: "comment",
            key: "10",
            title: this.$t("admin.users.webmasters.table.labels.comment"),
            align: "left",
            width: 200,
            renderBodyCell: ({ row }) => {
              return (
                <div class="is-flex is-align-items-center">
                  <ShowMore
                      data={ row.comment }
                      max-string={ 50 }>
                  </ShowMore>
                  <DropdownComment
                      ref={ `dropdown-${ row.id }` }
                      is-loading={ this.isLoading.updateComment }
                      value={ row.comment }
                      v-on:update={ () => this.changeComment(row.id) }>
                  </DropdownComment>
              </div>
              );
            }
          },
          {
            key: "11",
            align: "center",
            renderBodyCell: ({ row }) =>
              <WebmastersTableViewOptions
                  row={ row }
                  v-on:updateBind={ this.onToggleBind }
                  v-on:updateFinancesCostsCreatePaymentModal={ this.financesCostsCreatePaymentModalOpen }
                  v-on:updateShowHistory={ this.onShowHistory }>
              </WebmastersTableViewOptions>
          },
          {
            key: "12",
            align: "center",
            renderBodyCell: ({ row }) => <WebmastersTableViewApproveOrSingIn row={ row } />
          }
        ];
      }
    },
    methods: {
      ...mapActions("admin/users/webmasters", {
        ADD_WEBMASTER_PERSONAL_MANAGER,
        DELETE_WEBMASTER_PERSONAL_MANAGER,
        GET_WEBMASTERS,
        GET_WEBMASTERS_WITH_BALANCE,
        GET_HISTORY_WEBMASTER,
        UPDATE_ADMINS_DICTIONARY,
        EDIT_WEBMASTER_ADMIN_COMMENT,
        GET_WEBMASTERS_BY_ID
      }),

      ...mapActions("admin", {
        UPDATE_CREATE_PAYMENT_MODAL_ACTIVE: `finances/costs/createPaymentModal/${ UPDATE_MODAL_ACTIVE }`,
        UPDATE_IMPORT_ORDERS_BY_API_MODAL_ACTIVE: `users/webmasters/importOrdersByApiModal/${ UPDATE_MODAL_ACTIVE }`
      }),

      ...mapMutations("admin/users/webmasters", [
        UPDATE_WEBMASTERS_SORTINGS,
        EMPTY_WEBMASTER_EDITABLE_COMMENT,
        UPDATE_WEBMASTER_EDITABLE_COMMENT
      ]),

      coloredStatus (status) {
        return status ? "is-success" : "is-danger";
      },

      onToggleBind ({ id, admins, login }) {
        this.webmasterUserId = id;
        this.webmasterUserLogin = login;
        this.admin = admins;
        this.isBindModalVisible = true;
      },

      async onShowHistory (id) {
        await this.GET_HISTORY_WEBMASTER(id);
        this.isHistoryDialogVisible = true;
      },

      webmastersTableDetailsModalOpen (value) {
        this.detailsModal = true;
        this.detailsModalData = value;
      },

      updateIsVisiblePreferredCurrency (value) {
        this.isVisiblePreferredCurrency = value;
      },

      financesCostsCreatePaymentModalOpen (id) {
        this.webmasterUserId = id;
        this.UPDATE_CREATE_PAYMENT_MODAL_ACTIVE(true);
      },

      async changeComment (userId) {
        const comment = this.$refs[`dropdown-${ userId }`].comment;

        try {
        this.UPDATE_WEBMASTER_EDITABLE_COMMENT({ userId, comment });
        await this.EDIT_WEBMASTER_ADMIN_COMMENT();

        this.GET_WEBMASTERS_WITH_BALANCE();
        this.$refs[`dropdown-${ userId }`].toggle();
        this.$message({
          message: this.$t("admin.efficiency.plans.messages.changeCommentSuccess"),
          type: "success"
        });
       } catch (_) {
        this.$message({
          message: this.$t("admin.efficiency.plans.messages.changeCommentError"),
          type: "warning"
        });
      }
      },

      sortOption (params) {
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            if (params[key] !== "") {
              const sort = key;
              const order = params[key];
              this.UPDATE_WEBMASTERS_SORTINGS({
                  sort,
                  order
              });
              this.GET_WEBMASTERS_WITH_BALANCE();
            }
          }
        }
      },

      formatEmptyString,
      formatCurrency,
      toFixed,
      moment
    },

    watch: {
      detailsModal (value) {
        if (value === false) this.detailsModalData = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
    .line-through {
        position: relative;

        &:after {
            content: "";
            top: 0;
            left: 0;
            width: 4px;
            height: 120%;
            position: absolute;
            transform: translate(5px, -2px) rotateZ(-45deg);
            background-image: linear-gradient(90deg, #B5B5B5 50%, white 50%);
        }
    }
</style>
