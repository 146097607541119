<template>
    <InlineLoader
        v-if="balance.length === 0"
        active>
    </InlineLoader>
    <span v-else>
        <span
            :key="idx"
            v-for="(item, idx) in balance">
            <template
                v-if="preferredCurrency === balance[idx].balance.currency
                    && isVisiblePreferredCurrency">
                {{ formatCurrency(
                    toFixed(balance[idx].balance.value, 2),
                    balance[idx].balance.currency
                ) }}
            </template>
            <template v-if="!isVisiblePreferredCurrency">
                {{ formatCurrency(toFixed(item.balance.value, 2), item.balance.currency) }} <br>
            </template>
        </span>
    </span>
</template>

<script>
  import InlineLoader from "@/components/Common/InlineLoader";
  import { formatCurrency, toFixed } from "@core/filters";

  export default {
    name: "BalanceOutput",
    components: {
      InlineLoader
    },
    props: {
      balance: {
        type: Array,
        default: () => []
      },
      isVisiblePreferredCurrency: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      preferredCurrency () {
        return this.$store.state.auth.preferredCurrency;
      }
    },
    methods: {
      formatCurrency,
      toFixed
    }
  };
</script>

<style scoped>

</style>
