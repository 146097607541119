<template>
    <div class="wrapper">
        <WebmastersFilters></WebmastersFilters>
        <WebmastersTable></WebmastersTable>
        <WebmastersEditModal></WebmastersEditModal>
    </div>
</template>

<script>
  import WebmastersEditModal from "@/components/Admin/Users/WebmastersTab/WebmastersEditModal";
  import WebmastersFilters from "@/components/Admin/Users/WebmastersTab/WebmastersFilters.vue";
  import WebmastersTable from "@/components/Admin/Users/WebmastersTab/WebmastersTable.vue";
  import { prepareBooleanString } from "@core/helpers/prepareBooleanString";
  import { DebounceUpdateByWatchedParams } from "@core/mixins";
  import { GET_WEBMASTERS_WITH_BALANCE } from "@core/store/action-constants";
  import { SET_EMPTY, UPDATE_WEBMASTERS_FILTERS, UPDATE_WEBMASTERS_PAGINATION } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapMutations, mapState } from "vuex";
  
  export default {
    name: "UsersWebmasters",
    
    mixins: [DebounceUpdateByWatchedParams],
    
    components: {
      WebmastersFilters,
      WebmastersTable,
      WebmastersEditModal
    },

    permissions: [
      "WEBMASTERS.LIST.ALL",
      "WEBMASTERS.LIST.OWN",
      "WEBMASTERS.LIST.UNBIND"
    ],

    created () {
      const {
        page,
        perPage,
        loginOrIntId,
        isBlocked,
        isApproved,
        isEmailConfirmed,
        isPartnerNetwork,
        manager
      } = this.$route.query;

      if (page) {
        this.page = Number(page);
      }

      if (perPage) {
        this.perPage = Number(perPage);
      }

      this.UPDATE_WEBMASTERS_FILTERS({
        ...loginOrIntId && { loginOrIntId },
        ...isBlocked && { isBlocked: prepareBooleanString(isBlocked) },
        ...isApproved && { isApproved: prepareBooleanString(isApproved) },
        ...isEmailConfirmed && { isEmailConfirmed: prepareBooleanString(isEmailConfirmed) },
        ...isPartnerNetwork && { isPartnerNetwork: prepareBooleanString(isPartnerNetwork) },
        ...manager && { manager }
      });
    },

    computed: {
      ...mapFields("admin/users/webmasters", {
        fields: ["perPage", "page"],
        base: "pagination",
        action: UPDATE_WEBMASTERS_PAGINATION
      }),

      ...mapState("admin/users/webmasters", {
        updateParams: ({ pagination, filters }) => ({ pagination, filters })
      })
    },

    methods: {
      ...mapActions("admin/users/webmasters", [
        GET_WEBMASTERS_WITH_BALANCE,
        SET_EMPTY
      ]),

      ...mapMutations("admin/users/webmasters", [
        UPDATE_WEBMASTERS_FILTERS
      ]),

      async updated () {
        await this.GET_WEBMASTERS_WITH_BALANCE();
  
        const query = {
          ...this.updateParams.filters,
          ...this.updateParams.pagination
        };
  
        this.$router.push({
          query
        }).catch(_ => {
        });
      }
    },
    
    destroyed () {
      this.SET_EMPTY();
    }
  };
</script>

<style scoped lang="scss">
    @import "~bulma";
    @import "~buefy/src/scss/buefy";
</style>
