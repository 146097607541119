<template>
    <div class="is-flex is-align-items-center nowrap">
        <ColoredStatus
            :tooltip="$t(`admin.users.webmasters.filters.values.${ row.isApproved ? 'confirmed' : 'unconfirmed' }`)"
            position="both"
            tooltip-position="is-bottom"
            tooltip-type="is-dark"
            :type="coloredStatus(row.isApproved)">
            {{ formatEmptyString(row.login) }}

            <template slot="after">
                <LTooltip
                    v-show="row.isPartnerNetwork"
                    :label="$t(`admin.users.webmasters.filters.values.isPartnerNetwork`)"
                    position="is-bottom"
                    type="is-dark">
                    <FIcon
                        pack="fab"
                        type="success"
                        icon="hubspot">
                    </FIcon>
                </LTooltip>

                <LTooltip
                    v-show="row.activity.isBlocked"
                    :label="$t(`admin.users.webmasters.filters.values.blocked`)"
                    position="is-bottom"
                    type="is-dark">
                    <FIcon
                        icon="ban"
                        type="dark">
                    </FIcon>
                </LTooltip>

                <LTooltip
                    v-show="!row.activity.isConfirmed"
                    :label="$t(`admin.users.webmasters.filters.values.emailUnConfirmedIcon`)"
                    position="is-bottom"
                    type="is-dark">
                    <FIcon
                        icon="envelope"
                        type="dark">
                    </FIcon>
                </LTooltip>
            </template>
        </ColoredStatus>

        <FTag
            v-show="row.isEnableApi"
            custom-class="mr-auto ml-2 px-2"
            type="warning">
            {{ $t("admin.users.webmasters.filters.values.isEnableApi") }}
        </FTag>

        <div class="ml-auto">
            <LTooltip
                v-show="row.isApproved"
                :label="$t(`admin.users.webmasters.table.values.detailsModalLabel`)"
                class="ml-2"
                position="is-bottom"
                type="is-info">
                <FTag
                    custom-class="flex items-center px-2"
                    type="primary plain"
                    @click="$emit('updateDetailsModalData', row)">
                    <FIcon
                        icon="signal"
                        type="primary">
                    </FIcon>
                </FTag>
            </LTooltip>
        </div>
    </div>
</template>

<script>
  import ColoredStatus from "@/components/Common/ColoredStatus";
  import FTag from "@/components/Common/FTag";
  import { formatEmptyString } from "@core/filters";
  import FIcon from "@/components/Common/FIcon";
  
  export default {
    name: "WebmastersTableViewGroup",
    components: {
      FIcon,
      FTag,
      ColoredStatus
    },
    props: {
      row: {
        type: Object,
        required: true
      }
    },

    methods: {
      coloredStatus (status) {
        return status ? "is-success" : "is-danger";
      },

      formatEmptyString
    }
  };
</script>

<style scoped>

</style>