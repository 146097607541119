<template>
    <PaymentModal
        :is-active="isCreatePaymentModalActive"
        :is-loading="isLoading"
        :user-id="user"
        @submit="createPayment"
        @update:user="getBalance"
        @update:isActive="UPDATE_MODAL_ACTIVE($event)">
        <template #user="{ modal }">
            <b-field
                :label="$t(`admin.finances.costs.modal.body.webmaster.title`)"
                expanded>
                <FSelect
                    v-model="modal.user.id"
                    :disabled="!!user"
                    :get-data-vuex="getWebmasters"
                    :min-required-length="1"
                    field="login">
                    <template #text="option">
                        <UserOutput :user="option.option"></UserOutput>
                    </template>
                    <template #selected-option="option">
                        <UserOutput :user="option.option"></UserOutput>
                    </template>
                </FSelect>
            </b-field>
            <b-field
                v-if="selectedWebmasterBalance"
                :label="$t(`admin.finances.costs.modal.body.webmaster.balance`)">
                <b-button
                    class="has-text-weight-bold is-paddingless has-background-white is-12 column"
                    type="is-white">
                    <h5
                        :key="idx"
                        v-for="(item, idx) in selectedWebmasterBalance"
                        class="title is-5 has-text-right mb-0">
                        {{ formatCurrency(toFixed(item.balance.value, 2), item.balance.currency) }}
                    </h5>
                </b-button>
            </b-field>
        </template>
    </PaymentModal>
</template>

<script>
  import { TableVuex } from "@core/mixins";
  import {
    CREATE_COST,
    GET_COSTS,
    GET_INITIAL_WEBMASTERS,
    GET_WEBMASTER_BALANCE,
    GET_WEBMASTER_BY_ID,
    GET_WEBMASTERS_LIST,
    UPDATE_MODAL_ACTIVE
  } from "@core/store/action-constants";
  import {
    UPDATE_MODAL_OPTIONS,
    UPDATE_WEBMASTERS_FILTERS,
    UPDATE_WEBMASTERS_LIST_PAGINATION
  } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
  import FSelect from "@/components/Common/FSelect";
  import UserOutput from "@/components/Common/UserOutput";
  import PaymentModal from "@/components/Admin/Finances/PaymentModal";
  
  export default {
    name: "FinancesCostsCreatePaymentModal",
    mixins: [TableVuex],
    components: {
      PaymentModal,
      FSelect,
      UserOutput
    },
    props: {
      user: {
        type: String,
        default: null
      }
    },
    
    computed: {
      ...mapState("admin/finances/costs/createPaymentModal", {
        webmastersList: state => state.webmastersList.data,
        selectedWebmasterBalance: state => state.selectedWebmasterBalance,
        isCreatePaymentModalActive: state => state.isCreatePaymentModalActive
      }),

      ...mapFields("admin/finances/costs/createPaymentModal", {
        fields: ["userId", "amount", "requisiteId", "paymentSystemId", "comment"],
        base: "options",
        action: UPDATE_MODAL_OPTIONS
      }),

      ...mapGetters("admin/finances/costs/createPaymentModal", {
        selectedWebmaster: `webmastersList/${ GET_WEBMASTER_BY_ID }`
      }),

      isDisabledRequisites () {
        return !this.userId || !this.paymentSystemId;
      },

      isLoading () {
        return this.$wait(`admin/finances/costs/createPaymentModal/${ CREATE_COST }`);
      }
    },

    methods: {
      ...mapMutations("admin/finances/costs/createPaymentModal", {
        UPDATE_WEBMASTERS_LIST_PAGINATION: `webmastersList/${ UPDATE_WEBMASTERS_LIST_PAGINATION }`,
        UPDATE_WEBMASTERS_FILTERS: `webmastersList/${ UPDATE_WEBMASTERS_FILTERS }`
      }),

      ...mapActions("admin/finances/costs/createPaymentModal", {
        CREATE_COST,
        UPDATE_MODAL_ACTIVE,
        GET_WEBMASTER_BALANCE,
        GET_INITIAL_WEBMASTERS: `webmastersList/${ GET_INITIAL_WEBMASTERS }`,
        GET_WEBMASTERS_LIST: `webmastersList/${ GET_WEBMASTERS_LIST }`
      }),

      ...mapActions("admin/finances/costs", {
        GET_COSTS
      }),

      async getWebmasters (label = "") {
        if (this.user) {
          label = this.user;
          await this.GET_INITIAL_WEBMASTERS([this.user]);
        } else {
          this.UPDATE_WEBMASTERS_LIST_PAGINATION();
          this.UPDATE_WEBMASTERS_FILTERS({ loginOrIntId: label?.length > 0 ? label : null });
          await this.GET_WEBMASTERS_LIST();
        }
        return this.webmastersList;
      },

      getBalance (value) {
        this.userId = value.id;
        this.GET_WEBMASTER_BALANCE();
      },

      async createPayment (modal) {
        this.userId = modal.user.id;
        this.amount = modal.amount;
        this.requisiteId = modal.requisite?.id;
        this.paymentSystemId = modal.paymentSystem.id;
        this.comment = modal.comment;

        await this.CREATE_COST();
        this.$emit("update");
        this.UPDATE_MODAL_ACTIVE(false);
      }
    }
  };
</script>

<style scoped></style>
