<template>
    <div v-if="data">
        <div
            class="ve-table-wrapper"
            style="overflow: auto">
            <ve-table
                id="loading-container"
                :style="{'min-width': `${ minWidth }px`, 'word-break': wordBreak}"
                class="mt-6 mobile:mt-0"
                :columns="columns"
                :table-data="data"
                :cell-style-option="cellStyleOption"
                :footer-data="footerData"
                :sort-option="sortOption"
                :event-custom-option="eventCustomOption">
            </ve-table>

            <div
                v-if="data.length === 0"
                class="empty">
                <slot name="empty">
                    <span>{{ $t('common.table.empty') }}</span>
                </slot>
            </div>
        </div>
        
        <FPagination
            v-if="isPaginated"
            :data="data"
            :page-sizes="pageSizes"
            :per-page="perPage"
            :current-page="currentPage"
            :total="total"
            :per-page-change="updatePerPage"
            class="mt-6"
            is-search
            hide-on-single-page
            @current-change="updateCurrentPage"
            @perPageChange="$emit('update:perPage', $event)">
        </FPagination>
    </div>
</template>

<script>
  import FPagination from "@/components/Common/FPagination";
  import { Table } from "@core/mixins";

  export default {
    name: "CustomizableTable",
    mixins: [Table],
    components: {
      FPagination
    },
    props: {
      
      columns: {
        type: Array,
        default: null
      },
      data: {
        type: Array,
        default: null
      },
      footerData: {
        type: Array,
        default: () => []
      },
      sortChange: {
        type: Function,
        default: null
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      minWidth: {
        type: [Number, String],
        default: 0
      },
      total: {
        type: Number,
        default: 0
      },
      page: {
        type: [Number, String],
        default: 1
      },
      perPage: {
        type: [Number, String],
        default: 25
      },
      eventCustomOption: {
        type: [Function, Object],
        default: null
      },
      wordBreak: {
        type: String,
        default: "normal"
      }
    },
    
    mounted () {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#loading-container"),
        name: "wave"
      });
    },
    
    data () {
      return {
        cellStyleOption: {
          headerCellClass: () => {
            return "table-header-cell-class";
          }
        },
        sortOption: {
          sortChange: (params) => {
            this.sortChange(params);
          }
        }
      };
    },
    
    computed: {
      isPaginated () {
        return this.data && !isNaN(this.total) ? this.total > this.perPage : false;
      }
    },
    
    methods: {
      show () {
        this.loadingInstance.show();
      },
        
      close () {
        this.loadingInstance.close();
      },
        
      pageNumberChange (value) {
        this.$emit("update:page", value);
      }
    },
    
    watch: {
      isLoading (value) {
        if(value) {
          this.show();
        } else {
          this.close();
        }
      }
    },
    
    destroyed () {
      this.loadingInstance.destroy();
    }
  };
</script>

<style scoped lang="scss">
    ::v-deep {
        .ve-table-header-tr {
            background-color: #9AA3B4;
        }

        .table-header-cell-class {
            background-color: transparent !important;
            color: white!important;
            font-weight: 400!important;
        }
        
        .active {
            color: white!important;
        }
    
        .ve-pagination {
            margin-top: 24px;
            
            .ve-pagination-li {
                border: none;
                min-width: 64px;
                box-shadow: 0 6px 10px rgba(22, 34, 57, 0.05);
                
                &.ve-pagination-li-active {
                    border-bottom: 1px solid #5921A0;
                    
                    a {
                        color: #162239;
                        font-weight: 400;
                    }
                }
            }
        }
    
        .empty {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
            width: 100%;
            color: #666;
            font-size: 16px;
            border-top: 0;
        }

        .ve-table .ve-table-container.ve-table-border-around {
            border: none;
        }
    
        .table-header-cell-class:first-child {
            border-radius: 5px 0 0 0;
        }
        .table-header-cell-class:last-child {
            border-radius: 0 5px 0 0;
        }
    
        .ve-table-sort {
            top: -4px;
            //display: unset !important;
        
            i {
                font-family: "Font Awesome 5 Free" !important;
                font-weight: 600;
            
                &:first-child::before,
                &:last-child::before {
                    font-size: 9px;
                }
            
                &:first-child::before {
                    content: "\f077";
                }
            
                &:last-child::before {
                    content: "\f078";
                }
            }
        }
    }
    
    .ve-table-wrapper {
        box-shadow: 0px 10px 20px rgba(38, 50, 72, 0.05) !important;
        border-radius: 5px;
    }
</style>